/* eslint-disable react/jsx-one-expression-per-line */
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import AboutPerson from "@images/about-person.png";


import "@stylesPages/About.scss";

import SEO from "@components/SEO";
import Reviews from "@components/Reviews";

import ContactForm from "@containers/ContactForm";
import Layout from "@components/Layout";

export default function Index({ location: { pathname } }) {
  const { t } = useTranslation("about");
  return (
    <Layout pathname={pathname}>
      <SEO title="About" />
      <section className="aboutUs">
        <div className="aboutUs__container">
          <div className="aboutUs__grid">
            <div className="aboutUs__box">
              <h2 className="aboutUs__title">{t("about-title")}</h2>
              <p className="aboutUs__paragraph">{t("about-text")}</p>
            </div>
            <div className="about__box aboutUs__box--image">
              <img src={AboutPerson} alt="Cursos" className="about__person" />
            </div>
          </div>
        </div>
      </section>
      <section className="values" id="about_values">
        <div className="values__container">
            <div className="values__mision">
                <div>
                    <article className="values__item">
                        <h2 className="values__title" >{t("about-subtitle-vision")}</h2>
                        <p className="values__text">{t("about-text-vision")}</p>
                    </article>
                        <article className="values__item">
                        <h2 className="values__title--vision" >{t("about-subtitle-mission")}</h2>
                    <p className="values__text">{t("about-text-mission")}</p>
                    </article>
                </div>
                <div >
                    <article className="values__item">
                        <h2 className="values__title" >{t("about-subtitle-values")}</h2>
                    </article>
                    <article className="values__item">
                        <h2 className="values__subtitle" >{t("about-values-1")}</h2>
                        <p className="values__text">{t("about-values-text-1")}</p>
                    </article>
                    <article className="values__item">
                        <h2 className="values__subtitle" >{t("about-values-2")}</h2>
                        <p className="values__text">{t("about-values-text-2")}</p>
                    </article>
                    <article className="values__item">
                        <h2 className="values__subtitle" >{t("about-values-3")}</h2>
                        <p className="values__text">{t("about-values-text-3")}</p>
                    </article>
                    <article className="values__item">
                        <h2 className="values__subtitle" >{t("about-values-4")}</h2>
                        <p className="values__text">{t("about-values-text-4")}</p>
                    </article>
                </div>
            </div>    
        </div>
    </section>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
